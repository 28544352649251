import React from 'react'
import styled from 'styled-components'
import { colors } from '../GlobalStyles/vars/colors'

const Container = styled.div`
  text-align: center;
  color: ${colors.brightWhite};
  max-width: 400px;
`

interface Props {
  name?: string
}

export const FormSubmited = ({ name }: Props): JSX.Element => {
  return (
    <Container>
      <h2>Thank you!</h2>
      <p>Thank you{name && `, ${name}, `}for reaching out to us. An instructor will get back to you soon!</p>
    </Container>
  )
}
