import { graphql } from 'gatsby'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import React from 'react'
import { ContactForm } from '../components/Forms/ContactForm'
import { Layout } from '../components/Layout/Layout'
import { PhoneEmail } from '../components/molecules/contactInfo/PhoneEmail'
import { SplitImage } from '../components/SplitImage'
import styled from 'styled-components'
import { GoogleMaps } from '../components/molecules/contactInfo/GoogleMaps'
import { SocialMedia } from '../components/molecules/contactInfo/SocialMedia'
import { glassmorph } from '../components/GlobalStyles/glassmorph'
import { device } from '../components/GlobalStyles/helpers/device'
import { colors } from '../components/GlobalStyles/vars/colors'
import { InLink } from '../components/atoms/ui/links'
import { SEO } from '../helpers/SEO'

const PageWrapper = styled.div`
  .rel-pos {
    position: relative;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 725px;
  text-align: center;

  .career {
    display: block;
  }
`

const Glass = styled.div`
  @media ${device.laptop} {
    ${glassmorph}
    position: absolute;
    top: calc(50vh - 80px);
    right: 10%;

    label {
      color: ${colors.brightWhite};
    }
  }
`

interface Props {
  data: {
    file: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
    sanitySiteSettings: {
      contactInfo: {
        phoneNumber: string
        email: string
        address: {
          googlePlaceId: string
          state: string
          street: string
          street2: string
          city: string
          zip: string
        }
        socialMedia: [
          {
            platform: string
            url: string
            user: string
            _key: string
          },
        ]
      }
    }
  }
}

const ContactUs = ({ data }: Props): JSX.Element => {
  return (
    <Layout>
      <SEO title="Contact" />
      <PageWrapper>
        <SplitImage
          className="rel-pos"
          fluid={data.file.childImageSharp.fluid}
          alt="airport terminal with a sign reading 'welcome to cincinnati lunken airport"
        >
          <Body>
            <h1>Reach Out</h1>

            <p>Contact Queen City Flying Service today to experence flight and learn about our programs.</p>
            <PhoneEmail
              phoneNumber={data.sanitySiteSettings.contactInfo.phoneNumber}
              email={data.sanitySiteSettings.contactInfo.email}
            />

            <SocialMedia horizontal socialMedia={data.sanitySiteSettings.contactInfo.socialMedia} />

            <GoogleMaps
              googlePlaceId={data.sanitySiteSettings.contactInfo.address.googlePlaceId}
              width="300px"
              height="300px"
            />

            {/* <InLink className="career" to="/careers">
              Join the Team
            </InLink> */}
          </Body>
        </SplitImage>
        <Glass>
          <ContactForm />
        </Glass>
      </PageWrapper>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  {
    file(relativePath: { eq: "HY_1107.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanitySiteSettings {
      contactInfo {
        phoneNumber
        email
        address {
          googlePlaceId
          state
          street
          street2
          city
          zip
        }
        socialMedia {
          platform
          user
          _key
          url
        }
      }
    }
  }
`
