import styled from 'styled-components'
import { colors } from '../../GlobalStyles/vars/colors'

const style = `
  background-color: ${colors.grey};
  border: none;
  margin: 0;
  width: 100%;
  padding: 0.3em 0.2em;
  alight-self: center;
  display: block;
  outline: none;
  border-bottom: 2px ${colors.grey} solid;
  
  ::placeholder {
    color: ${colors.lightPrimary};
  }
`

export const InputContainer = styled.input`
  ${style};
`

export const TextAreaContainer = styled.textarea`
  ${style};
`

export const Label = styled.label`
  font-size: 0.7em;
  padding: 0 0.2em;
  align-self: left;
`
