import React, { useState } from 'react'
import styled from 'styled-components'
import { SubmitButton } from '../atoms/ui/buttons'
import { Input } from './formElements/Input'
import { TextArea } from './formElements/TextArea'
import { FormSubmited } from './FormSubmited'

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`

export const ContactForm = (): JSX.Element => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
    botFeild: '',
    submited: false,
    'form-name': 'Contact',
  })

  const [submited, setSubmited] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  interface FormData {
    'form-name': string
    name: string
    email: string
    message: string
    botFeild: string
  }

  const encode = (data: FormData): string => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key as keyof FormData]))
      .join('&')
  }

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...formState,
      }),
    })
      .then(() => setSubmited(true))
      .catch((error) => alert(error))
  }

  return (
    <FormContainer
      method="POST"
      onSubmit={handleSubmit}
      action=""
      name="Contact"
      data-netlify="true"
      data-netlify-honeypot="bot-feild"
    >
      <input type="hidden" name="form-name" value={formState['form-name']} />
      <input hidden name="bot-feild" value={formState.botFeild} onChange={handleChange} />
      {submited ? (
        <FormSubmited name={formState.name} />
      ) : (
        <>
          <Input
            label="Name*"
            placeholder="Your Name"
            type="text"
            required={true}
            name="name"
            onChange={handleChange}
            value={formState.name}
          />
          <Input
            label="Email*"
            placeholder="email@website.com"
            type="email"
            required={true}
            name="email"
            onChange={handleChange}
            value={formState.email}
          />
          <TextArea
            label="Message*"
            placeholder="What's up?"
            name="message"
            onChange={handleChange}
            value={formState.message}
            required={true}
          />
          <SubmitButton type="submit">Submit</SubmitButton>
        </>
      )}
    </FormContainer>
  )
}
