import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Label } from './formElements'
import { colors } from '../../GlobalStyles/vars/colors'

const Container = styled.div`
  width: 95%;
  max-width: 250px;
  display: flex;
  flex-direction: column;

  textarea:focus + svg path.focus,
  input:focus + svg path.focus {
    stroke-dasharray: 40, 0;
    stroke-dashoffset: 0;
  }

  input:valid + svg path.valid,
  input.touched:invalid + svg path.invalid {
    stroke-dasharray: 40, 0;
    stroke-dashoffset: 0;
  }
`

const SVG = styled.svg`
  width: 100%;
  height: 2px;
  stroke-width: 2px;
  stroke: ${colors.black};
  padding: 0 0.2em;
  margin-bottom: 4px;

  .focus,
  .valid,
  .invalid {
    transition: all 0.2s;
    stroke-dasharray: 0, 20;
    stroke-dashoffset: -20;
  }

  .focus {
    stroke: ${colors.black};
  }

  .valid {
    stroke: ${colors.approve};
  }

  .invalid {
    stroke: ${colors.danger};
  }
`

interface Props {
  children: ReactElement | ReactElement[]
  label: string
  name: string
}

export const InputWrapper = (props: Props): JSX.Element => {
  return (
    <Container>
      <Label htmlFor={props.name}>{props.label}</Label>
      {props.children}
      <SVG viewBox="0 0 40 2" preserveAspectRatio="none">
        <path d="M0 1 L40 1" className="focus" />
        <path d="M0 1 L40 1" className="invalid" />
        <path d="M0 1 L40 1" className="valid" />
      </SVG>
    </Container>
  )
}
