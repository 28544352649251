/*
  Takes a Google Place API Id and renders an interactive Google Map using the Maps Embeded API.
  
  Requires a G_API_KEY
  Takes optional width/height props to set the size of the map.
  Takes optional classes and applies them to the container.
*/
import React from 'react'

interface Props {
  width?: string
  height?: string
  className?: string
  googlePlaceId: string
}

export const GoogleMaps = ({ width, height, googlePlaceId, className }: Props): JSX.Element => {
  return (
    <iframe
      className={className}
      style={{ border: '0' }}
      width={width || '400'}
      height={height || '400'}
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_G_API_KEY}&q=place_id:${googlePlaceId}`}
      allowFullScreen
      frameBorder="0"
    />
  )
}
