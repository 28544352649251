import React from 'react'
import { InputContainer } from './formElements'
import { InputWrapper } from './InputWrapper'

interface Props {
  type: string
  required: boolean
  placeholder: string
  label: string
  name: string
  onChange: React.ChangeEventHandler
  value: string
}

export const Input = (props: Props): JSX.Element => {
  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.classList.add('touched')
  }
  return (
    <InputWrapper label={props.label} name={props.name}>
      <InputContainer
        type={props.type}
        id={props.name}
        name={props.name}
        required={props.required}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={handleBlur}
      />
    </InputWrapper>
  )
}
